import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import ScrollIndicator from './ScrollIndicator';

const Layout = ({ children }) => {
  const [isScrollIndicatorVisible, setIsScrollIndicatorVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrollIndicatorVisible(false);
      } else {
        setIsScrollIndicatorVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className="App font-sans text-white"
      style={{
        backgroundColor: 'rgba(28, 18, 35, 255)',
        minHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <Navbar />
      <main className="pt-16 relative">
        {children}
        <ScrollIndicator isVisible={isScrollIndicatorVisible} />
      </main>

      {/* Hide the scrollbar globally using CSS */}
      <style jsx global>{`
        /* Hide scrollbar for Chrome, Safari, and Opera */
        ::-webkit-scrollbar {
          display: none;
        }

        /* Hide scrollbar for IE, Edge, and Firefox */
        body {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      `}</style>
    </div>
  );
};

export default Layout;
