// components/ScrollIndicator.js
import React from 'react';

const ScrollIndicator = ({ isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2">
      <div className="mouse">
        <div className="wheel"></div>
      </div>
    </div>
  );
};

export default ScrollIndicator;
