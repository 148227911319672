// components/Navbar.js
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [navLoaded, setNavLoaded] = useState(false);

  useEffect(() => {
    setNavLoaded(true);

    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsNavbarVisible(scrollTop <= lastScrollTop);
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileMenuOpen]);

  return (
    <header className={`fixed top-2 left-0 w-full z-50 transition-opacity duration-1000 transition-transform duration-300 ${navLoaded ? 'opacity-100' : 'opacity-0'} ${isNavbarVisible ? 'transform translate-y-0' : 'transform -translate-y-full'}`}>
      <nav className="bg-transparent">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-shrink-0">
              <NavLink to="/" className="text-5xl font-bold text-white">
                Nero.
              </NavLink>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-6">
                {['Home', 'About Me', 'Blog', 'Portfolio', 'Contact'].map((item, index) => (
                  <NavLink
                    key={item}
                    to={`/${item.toLowerCase().replace(' ', '')}`}
                    className={({ isActive }) =>
                      `px-3 py-2 rounded-md text-1xl font-medium text-white hover:text-purple-500 transition duration-300 ${
                        isActive ? 'border-b-2 border-purple-500' : ''
                      }`
                    }
                  >
                    {item}
                  </NavLink>
                ))}
              </div>
            </div>
            <div className="md:hidden">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                type="button"
                className="text-gray-400 hover:text-white focus:outline-none focus:text-white transition duration-300"
                aria-controls="mobile-menu"
                aria-expanded={isMobileMenuOpen}
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {isMobileMenuOpen ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 8h16M4 16h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`md:hidden absolute top-full left-0 w-full transition-transform duration-300 ${isMobileMenuOpen ? 'transform scale-y-100' : 'transform scale-y-0'} origin-top bg-[#1E1E2E] bg-opacity-90 backdrop-filter backdrop-blur-sm`}
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {['Home', 'About Me', 'Blog', 'Portfolio', 'Contact'].map((item) => (
              <NavLink
                key={item}
                to={`/${item.toLowerCase().replace(' ', '')}`}
                className={({ isActive }) =>
                  `block px-3 py-2 rounded-md text-base font-medium text-white hover:text-purple-500 transition duration-300 ${
                    isActive ? 'border-b-2 border-purple-500' : ''
                  }`
                }
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {item}
              </NavLink>
            ))}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
