import React, { useEffect, useState } from 'react';

const Header = () => {
  const fullText = "Hi, I'm Nero.";
  const fullTagline = 'I solve problems with inventive ideas and effective solutions';
  const [typedText, setTypedText] = useState('');
  const [showTagline, setShowTagline] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    let indexTitle = 0;

    const typeTitle = setInterval(() => {
      if (indexTitle < fullText.length) {
        setTypedText((prev) => prev + fullText.charAt(indexTitle));
        indexTitle++;
      }

      if (indexTitle >= fullText.length) {
        setShowTagline(true);
        setIsFadingOut(true);
        setIsTyping(false);
        clearInterval(typeTitle);
      }
    }, 150);

    return () => {
      clearInterval(typeTitle);
    };
  }, []);

  return (
    <section className="min-h-screen flex flex-col items-center justify-center px-4 relative max-w-5xl mx-auto z-10">
      {/* Background SVG Animation */}
      <svg
  width="95vw" // Full viewport width
  height="100%" // Full height of the parent container
  viewBox="0 0 800 400" // Keep the viewBox as is
  preserveAspectRatio="xMidYMid meet" // Ensures the aspect ratio remains intact and centers the content
  className="absolute inset-0 z-0 pointer-events-none"
  style={{
    filter: 'drop-shadow(0 0 8px rgba(155, 89, 182, 0.7))',
    animation: 'glowPulse 3s infinite alternate',
    left: '50%', // Move the SVG to the middle of the screen horizontally
    transform: 'translateX(-50%)', // Shift it back by half of its width to center
  }}
>
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
      <stop offset="0%" stopColor="#A020F0" />
      <stop offset="100%" stopColor="#FF00FF" />
    </linearGradient>
  </defs>
  
  <path
    d="M 100 200 Q 250 100, 400 200 T 700 200"
    stroke="url(#gradient)"
    strokeWidth="2"
    fill="none"
    opacity="0.4"
  />
  <path
    d="M 50 220 Q 200 120, 400 220 T 750 220"
    stroke="url(#gradient)"
    strokeWidth="2"
    fill="none"
    opacity="0.3"
  />
  <path
    d="M 0 240 Q 150 140, 400 240 T 800 240"
    stroke="url(#gradient)"
    strokeWidth="2"
    fill="none"
    opacity="0.2"
  />
</svg>


      {/* Text Animation */}
      <div className="text-center relative z-10">
        <h1 className="text-5xl md:text-7xl font-bold text-white mb-8 min-h-[3rem] flex justify-center items-center">
          {typedText}
          <span
            className={`blinking-caret ${isFadingOut ? 'fade-out' : ''}`}
            aria-hidden="true"
          ></span>
        </h1>
        <p
          className={`italic text-xl md:text-3xl text-gray-300 mb-12 max-w-3xl mx-auto transition-opacity duration-1000 ${
            showTagline ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {fullTagline}
        </p>
      </div>
    </section>
  );
};

export default Header;
